import "./styles/main.css";
import { Elm } from "./Main.elm";
import registerServiceWorker from "./registerServiceWorker";
import smoothscroll from "smoothscroll-polyfill";
import throttle from "lodash/throttle";

const app = Elm.Main.init({
  node: document.getElementById("root")
});

registerServiceWorker();
smoothscroll.polyfill();

app.ports.openTypeForm.subscribe(url => {
  window.typeformEmbed
    .makePopup(url, {
      hideHeaders: true,
      hideFooter: true
    })
    .open();
});

app.ports.scrollTo.subscribe(id => {
  document.getElementById(id).scrollIntoView({
    behavior: "smooth"
  });
});

const scrollEls = [...document.getElementsByClassName("hash-scroll")].reverse();
const defaultEl = document.getElementById("products");
window.addEventListener(
  "scroll",
  throttle(() => {
    const item =
      [...scrollEls].find(el => el.getBoundingClientRect().top <= 500) ||
      defaultEl;
    app.ports.activeLink.send(item.id);
  }, 300)
);
